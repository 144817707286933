import {
  Flex,
  VStack,
  Text,
  Container,
  Box,
  Button,
  Stack,
  Img,
  Tooltip,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { Constants } from "../../constants";

export const FirstView = () => {
  return (
    <Box
      width="full"
      h={{ base: "2xl", md: "xl" }}
      background="gray.900"
      position="relative"
      color="gray.50"
      _after={{
        content: '""',
        position: "absolute",
        right: 0,
        left: "-15%",
        top: "100%",
        zIndex: 10,
        display: "block",
        height: "18px",
        backgroundSize: "18px 100%",
        backgroundImage:
          "linear-gradient(135deg, #171923 25%, transparent 25%), linear-gradient(225deg, #171923 25%, transparent 25%)",
        backgroundPosition: "0 0",
      }}
    >
      <Flex w="full" h="full" alignItems="center" justifyContent="center">
        <Container maxW="container.md">
          <VStack textAlign="center">
            <Text fontSize="xl" fontWeight="bold">
              Who we are?... We are
            </Text>
            <Text
              as="h1"
              fontSize="7xl"
              bgGradient="linear(to-l, #7928CA, #FF0080)"
              bgClip="text"
              fontWeight="extrabold"
              position="relative"
              lineHeight="5rem"
              _before={{
                textShadow: "2px 2px 2px rgba(33,39,77,0.5)",
                content: `"${Constants.APP_NAME}"`,
                position: "absolute",
                zIndex: -1,
                left: 0,
                top: 0,
              }}
            >
              <Text as="span">{Constants.APP_NAME}</Text>
            </Text>
            <Text fontSize="xl" fontWeight="bold">
              Register as a remote worker, post your profile on the site &#x1f4dd;
            </Text>
            <Text fontSize="xl" fontWeight="bold">
              Wait to get offers across the world &#x1f30e;
            </Text>
          </VStack>

          <Stack
            mt="8"
            spacing="6"
            alignItems="center"
            justifyContent="center"
            direction={{ base: "column", md: "row" }}
          >
            <Tooltip
              hasArrow
              label="Share your github as a resume for free!"
              bg="gray.300"
              color="black"
            >
              <LinkBox>
                <NextLink href="/users/new" passHref>
                  <Button
                    as="a"
                    colorScheme="green"
                    boxShadow="md"
                    rounded="sm"
                    size="lg"
                    rightIcon={
                      <Img src="/github-colored.svg" alt="github icon" h="8" />
                    }
                    minW={{ base: "250px", md: "none" }}
                    position="relative"
                    _before={{
                      content: "'Free'",
                      fontSize: "xs",
                      bg: "yellow.500",
                      py: 0,
                      px: "1",
                      position: "absolute",
                      top: "-14px",
                      left: "6px",
                    }}
                  >
                    Link your Profile
                  </Button>
                </NextLink>
              </LinkBox>
            </Tooltip>
            <Tooltip
              hasArrow
              label="Are you a recruiter? post a job to put on the list!"
              bg="gray.300"
              color="black"
            >
              <LinkBox>
                <NextLink href="/jobs/new" passHref>
                  <Button
                    as="a"
                    colorScheme="red"
                    boxShadow="md"
                    rounded="sm"
                    size="lg"
                    rightIcon={
                      <Img src="/new-job.svg" alt="github icon" h="8" />
                    }
                    minW={{ base: "250px", md: "none" }}
                  >
                    Post Job for $200
                  </Button>
                </NextLink>
              </LinkBox>
            </Tooltip>
          </Stack>
        </Container>
      </Flex>
    </Box>
  );
};
