import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Img,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { signOut, signIn } from "next-auth/react";
import { useSession } from "next-auth/react";
import { useState } from "react";
import { GrLogin, GrLogout } from "react-icons/gr";
import { HeaderAd } from ".";

export const HeaderNav = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [position, setPosition] = useState<"sticky" | "unset">("unset");

  const onVisible = () => setPosition("unset");
  const onInvisible = () => setPosition("sticky");

  return (
    <>
      <HeaderAd onVisible={onVisible} onInvisible={onInvisible} />
      <Box position={position} top="0" zIndex="999">
        <Flex
          bg={useColorModeValue("gray.900", "gray.800")}
          color={useColorModeValue("gray.100", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          align={"center"}
        >
          <Flex
            flex={{ base: 1, md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
            <NextLink href="/" passHref>
              <Link
                textAlign={useBreakpointValue({ base: "center", md: "left" })}
                fontFamily={"heading"}
                color={useColorModeValue("gray.100", "white")}
              >
                <HStack>
                  <Img src="/logo.svg" alt="logo" height="24px" />
                  <Text fontWeight="bold">FRW</Text>
                </HStack>
              </Link>
            </NextLink>
          </Flex>

          <Stack
            flex={{ base: 1, md: 1 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav />
            </Flex>
          </Stack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    </>
  );
};

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.100", "gray.200");
  const linkHoverColor = useColorModeValue("blue.500", "white");
  const popoverContentBgColor = useColorModeValue("blackAlpha.900", "gray.800");
  const { status } = useSession();
  const isSignedIn = status === "authenticated";

  return (
    <Stack direction={"row"} alignItems="center" spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
      {isSignedIn ? (
        <Tooltip label="log out" hasArrow bg="gray.300" color="black">
          <IconButton
            colorScheme="yellow"
            onClick={() => signOut()}
            icon={<GrLogout />}
            rounded="sm"
            aria-label="sign out"
          />
        </Tooltip>
      ) : (
        <Tooltip label="log in" hasArrow bg="gray.300" color="black">
          <IconButton
            colorScheme="blue"
            onClick={() => signIn()}
            icon={<GrLogin />}
            rounded="sm"
            aria-label="sign in"
          />
        </Tooltip>
      )}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("blackAlpha.800", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "blue.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  const { status } = useSession();
  const isSignedIn = status === "authenticated";

  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}

      <Stack spacing={4}>
        <Flex
          py={2}
          as={Link}
          href="#"
          justify={"space-between"}
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
          bg={"none"}
          onClick={isSignedIn ? () => signOut() : () => signIn()}
        >
          <Text
            fontWeight={600}
            color={useColorModeValue("gray.600", "gray.200")}
          >
            {isSignedIn ? "Logout" : "Login "}
          </Text>
        </Flex>
      </Stack>
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  // {
  //   label: "Inspiration",
  //   children: [
  //     {
  //       label: "Explore Design Work",
  //       subLabel: "Trending Design to inspire you",
  //       href: "#",
  //     },
  //     {
  //       label: "New & Noteworthy",
  //       subLabel: "Up-and-coming Designers",
  //       href: "#",
  //     },
  //   ],
  // },
  {
    label: "Blogs",
    href: "/blogs/page/1"
  },
  {
    label: "Q & A",
    href: "/questions",
  },
  {
    label: "Employer Menu",
    children: [
      {
        label: "Agreement",
        subLabel: "Service Agreement",
        href: "/agreement",
      },
      {
        label: "Commerce",
        subLabel: "Act on specified commercial transactions",
        href: "/commerce",
      },
      {
        label: "Customer Portal",
        subLabel: "Manage your subscription",
        href: process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_LINK,
      },
    ],
  },
];
