import {
  Stack,
  Text,
  Container,
  Box,
  Button,
  VStack,
  Divider,
  HStack,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { Constants } from "../../constants";

const Footer = () => {
  return (
    <Stack bg="gray.900" color="gray.100" mt="24">
      <Container maxW="container.lg">
        <VStack my="24" alignItems="flex-start" width="full">
          <Text
            fontSize="2xl"
            fontWeight="bold"
            bgGradient="linear(to-l, #7928CA, #FF0080)"
            bgClip="text"
          >
            {Constants.APP_NAME}
          </Text>
          <Text fontSize="lg" fontWeight="bold" mt="4">
            <Text as="span">{Constants.APP_NAME}&nbsp;</Text>
            is the platform to find a full remote job all over across the world
            &#x1f30e;
            <br />
            No more over complex Job searching form&#x1f645;
          </Text>
          <Box pt="8" pb="4" width="full">
            <Divider />
          </Box>
          <HStack spacing="4" alignItems="center" justifyContent="flex-start">
            <Text>Or, Are you an Employer?</Text>
            <NextLink href="/jobs/new" passHref>
              <Button as="a" colorScheme="red" boxShadow="md" rounded="sm">
                Post a Job
              </Button>
            </NextLink>
          </HStack>
        </VStack>
      </Container>
    </Stack>
  );
};

export { Footer };
