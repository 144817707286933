import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

function getCountdown() {
  const now = new Date(); //今の日時
  const target = new Date(2023, 0, 1, 0, 0, 0);
  const remainTime = target.getTime() - now.getTime();

  //指定の日時を過ぎていたら処理をしない
  if (remainTime < 0) return "Time overed";

  //差分の日・時・分・秒を取得
  const difDay = Math.floor(remainTime / 1000 / 60 / 60 / 24);
  const difHour = Math.floor(remainTime / 1000 / 60 / 60) % 24;
  const difMin = Math.floor(remainTime / 1000 / 60) % 60;
  // const difSec = Math.floor(remainTime / 1000) % 60;

  return `${difDay} Days ${difHour} Hours ${difMin} Min`;
}

const AdCountDown = () => {
  const [time, setTime] = useState("0 Days 0:00");

  useEffect(() => {
    const interval = setInterval(() => {
      const count = getCountdown();
      setTime(count);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Text
      background="blackAlpha.300"
      py="1"
      px="2"
      ml="2"
      rounded="md"
      fontSize="sm"
      color="gray.50"
      fontWeight="bold"
    >
      {time}
    </Text>
  );
};

export { AdCountDown };
