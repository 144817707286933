import "../styles/globals.css";
import { ApolloProvider } from "@apollo/client";
import { ChakraProvider, Container, extendTheme } from "@chakra-ui/react";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import type { NextPage } from "next/types";
import { SessionProvider } from "next-auth/react";
import type { ReactElement, ReactNode } from "react";
import apolloClient from "../lib/apollo";
import { switchTheme } from "@components/Switch";
import { Footer, HeaderNav } from "@components/molecules";
import { ConditionalComponents } from "@components/pages/index/ConditionalComponents";
import { useClearCache } from "@hooks/useClearCache";
import { useGtag } from "@hooks/useGtag";
import { useToastMessage } from "@hooks/useToastMessage";

const AppModal = dynamic(
  () => import("@components/pages/index/AppModal").then((mod) => mod.AppModal),
  { ssr: false }
);

export const theme = extendTheme({
  components: {
    Switch: switchTheme,
  },
  styles: {
    global: {
      body: {
        backgroundColor: "gray.100",
        color: "gray.800",
      },
    },
  },
});

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  useToastMessage();
  useClearCache();
  useGtag();

  return (
    <SessionProvider session={session}>
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={theme}>
          <HeaderNav />
          <ConditionalComponents />
          <AppModal />

          <Container maxW="container.lg">
            {getLayout(<Component {...pageProps} />)}
          </Container>
          <Footer />
        </ChakraProvider>
      </ApolloProvider>
    </SessionProvider>
  );
}

export default MyApp;
