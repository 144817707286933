import { Box, Container, Stack, Text, useDimensions } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef } from "react";
import { AdCountDown } from "./AdCountDown";

const HeaderAd: FC<{ onVisible: () => void; onInvisible: () => void }> = ({
  onVisible,
  onInvisible,
}) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const isVisible = useRef<boolean>(true);
  const dimensions = useDimensions(boxRef, true);

  const watchCurrentVisibility = useCallback(() => {
    const height = dimensions?.borderBox.height;
    if (!boxRef.current || !height) return;

    // when box is not visible
    if (height <= window.scrollY && isVisible.current) {
      isVisible.current = false;
      return onInvisible();
    }

    // when box is visible
    if (height >= window.scrollY && !isVisible.current) {
      isVisible.current = true;
      onVisible();
    }
  }, [dimensions?.borderBox.height, onInvisible, onVisible]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    watchCurrentVisibility();
  }, [dimensions, watchCurrentVisibility]);

  return (
    <Box
      width="full"
      ref={boxRef}
      bgGradient="linear(to-r, cyan.700, purple.500)"
      p="2"
    >
      <Container maxW="container.lg">
        <Stack
          width="full"
          justifyContent="center"
          alignItems="center"
          direction={{ base: "column", md: "row" }}
        >
          <Text fontSize="md" color="gray.50">
            &#x1f496;&nbsp;We are offering 1 month trial till
          </Text>
          <AdCountDown />
          <Text fontSize="md" color="gray.50">
            later
          </Text>
        </Stack>
      </Container>
    </Box>
  );
};

export { HeaderAd };
