import { useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useEffect } from "react";

const useToastMessage = () => {
  const toast = useToast();
  const router = useRouter();

  const showToast = useCallback(() => {
    if (typeof window) {
      const params = router.query as { success?: string; type?: string };
      const isSucceeded = params.success === "true";
      const typeOfPost = params.type;
      if (isSucceeded && typeOfPost === "job") {
        toast({
          title: "Order Placed.",
          description:
            "We've successfully create you job. please wait until the job list are updated to list the job",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }

      if (isSucceeded && typeOfPost === "resume") {
        toast({
          title: "Profile Posted",
          description:
            "We've successfully create you Profile. please wait until profile list are updated to list your profile",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  }, [router.query, toast]);

  useEffect(() => {
    showToast();
  }, [showToast]);
};

export { useToastMessage };
