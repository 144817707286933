import {
  Box,
  Container,
  HStack,
  Img,
  Progress,
  VStack,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";

const IconWithTitle: FC<{
  isActive: boolean;
  image: string;
  imageName: string;
  title: string;
  subtitle: string;
}> = ({ isActive, image, imageName, title, subtitle }) => {
  const gray = "whiteAlpha.300";
  const red = "red.500";
  const color = isActive ? red : gray;
  const subColor = isActive ? "gray.100" : gray;

  return (
    <VStack
      display={{
        base: isActive ? "flex" : "none",
        md: "flex",
      }}
    >
      <Box background="gray.900" px="4">
        <Box rounded="full" background={color} p="4">
          <Img
            borderRadius="full"
            boxSize={"60px"}
            src={image}
            alt={imageName}
          />
        </Box>
      </Box>
      <Text fontWeight="bold" fontSize="2xl" color={color}>
        {title}
      </Text>
      <Text fontSize="lg" color={subColor}>
        {subtitle}
      </Text>
    </VStack>
  );
};

const Stepper: FC<{ progress: number }> = ({ progress }) => {
  let stepsToFinish = "3 more steps";
  if (progress === 50) stepsToFinish = "2 more sptes";
  if (progress === 100) stepsToFinish = "Last step";

  return (
    <Box
      width="full"
      background="gray.900"
      mb="24"
      position="relative"
      _after={{
        content: '""',
        position: "absolute",
        right: 0,
        left: "-15%",
        top: "100%",
        zIndex: 10,
        display: "block",
        height: "18px",
        backgroundSize: "18px 100%",
        backgroundImage:
          "linear-gradient(135deg, #171923 25%, transparent 25%), linear-gradient(225deg, #171923 25%, transparent 25%)",
        backgroundPosition: "0 0",
      }}
    >
      <Container maxW="container.lg">
        <Box textAlign="center" pt="24" pb="6">
          <Text fontWeight="bold" fontSize="4xl" color="gray.100">
            {stepsToFinish} to post a Job!!
          </Text>
        </Box>
        <VStack
          width="full"
          pb="24"
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          <Progress
            value={progress}
            width="98%"
            size="xs"
            colorScheme="red"
            position="absolute"
          />
          <HStack
            width="full"
            position="relative"
            justifyContent={{ base: "center", md: "space-between" }}
            spacing="0"
            pt="16"
          >
            <IconWithTitle
              isActive={progress <= 33}
              image="/form.svg"
              imageName="form"
              title="Step.1"
              subtitle="Job Information"
            />
            <IconWithTitle
              isActive={33 < progress && progress <= 66}
              image="/browser.svg"
              imageName="browser"
              title="Step.2"
              subtitle="Preview Job"
            />
            <IconWithTitle
              isActive={66 < progress}
              image="/payment.svg"
              imageName="payment"
              title="Step.3"
              subtitle="Select Plan"
            />
          </HStack>
        </VStack>
      </Container>
    </Box>
  );
};

export { Stepper };
