import { useRouter } from "next/router";
import { Stepper } from "../job/new/Stepper";
import { FirstView } from "@components/molecules/FirstView";

const ConditionalComponents = () => {
  const router = useRouter();
  const isIndex = router.pathname === "/";
  const isNew = router.pathname === "/jobs/new";
  const isPreview = router.pathname === "/jobs/new/preview";
  const isConfirm = router.pathname === "/jobs/new/confirm";

  return (
    <>
      {isIndex && <FirstView />}
      {isNew && <Stepper progress={0} />}
      {isPreview && <Stepper progress={50} />}
      {isConfirm && <Stepper progress={100} />}
    </>
  );
};

export { ConditionalComponents };
