import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const clipIcon = definePartsStyle({
  container: {
    height: "8",
    "--switch-track-height": "42px",
    "--switch-track-width": "4.875rem",
  },
  thumb: {
    backgroundImage: "/clip.svg",
    backgroundPosition: "center",
    backgroundSize: "80%",
    backgroundRepeat: "no-repeat",
  },
});

export const switchTheme = defineMultiStyleConfig({ variants: { clipIcon } });
