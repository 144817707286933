import { useCallback, useEffect } from "react";

const useClearCache = () => {
  const clearCache = useCallback(() => {
    if (typeof window) {
      const query = new URLSearchParams(window.location.search);

      if (query.get("success") && sessionStorage.getItem("title")) {
        sessionStorage.clear();
      }
    }
  }, []);

  useEffect(() => {
    clearCache();
  }, [clearCache]);
};

export { useClearCache };
